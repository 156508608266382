<template>
<div>
  <account-role-title role="Admin" />
  <!-- <breadcrumbs /> -->
  <span class="title d-block mb-2">{{ $t("Permissions") }}</span>
  <v-data-table
      :headers="headers"
      :items="desserts"
      class="elevation-1"
      :disable-pagination="true"
      :hide-default-footer="true"
  >
    <template v-slot:top>
        <span class="d-block pt-4 pb-4 pl-4">{{ $t('User\’s access rights to selected sections or pages') }}</span>
    </template>
    <template v-slot:body="props">
      <tbody>
      <tr v-for="index in props.items">
        <td v-for="header in headers" class="text-start">
          <span v-if="!roles.find(e => e.value === header.value)">{{ ucFirst(index[header.value]) }}</span>
          <span v-else>
              <v-switch
                  v-model="permissionsInput[index[header.value].id +'-'+ index.id]"
                  :value="permissionsInput[index[header.value].id +'-'+ index.id] = index[header.value].is_active"
                  @change="changePermission(!!permissionsInput[index[header.value].id +'-'+ index.id], index[header.value].id, index.id)"
                  :loading="switchLoading" :disabled="switchLoading">
              </v-switch>
            </span>
        </td>
      </tr>
      </tbody>
    </template>
  </v-data-table>
</div>
</template>

<script>
import axios from "@axios";
import {onBeforeMount, ref} from "@vue/composition-api";

export default {
  name: "RolesAndPermissions",
  setup() {
    const permissions = ref([])
    const switchLoading = ref(true)
    const permissionsInput = ref([])
    const roles = ref([])
    const changePermission = (val, roleId, permissionId) => {
      switchLoading.value = true
      axios.post('admin/update-permissions', {val, roleId, permissionId, _method: 'put'}).then(res => {
        switchLoading.value = false
      }).catch(e => {
        console.log(e)
      })
    }

    const headers = ref([
      {text: 'id', value: 'id'},
      {text: 'permission', value: 'name'},
    ])

    const ucFirst = (str) => {
      return str[0] ? str[0].toUpperCase() + str.slice(1) : str
    }

    onBeforeMount(() => {
      axios.get('admin/all-permissions').then(res => {
        permissions.value = res.data.data
        switchLoading.value = false
      })
      axios.get('admin/all-roles').then(res => {
        roles.value = res.data.data
        headers.value = headers.value.concat(roles.value).map((header) => (
          { ...header, text: ucFirst(header.text) }
        ))
      })
    })
    return {
      desserts: permissions,
      headers,
      roles,
      switchLoading,
      permissionsInput,
      changePermission,
      ucFirst
    }
  },
}
</script>
